import { isAuthenticated, setAuthentication } from "../app/store";
import axiosInstance from "./AxiosInstance";

interface UserData {
    email: string;
    password: string;
  }

  interface ResetData {
    userId: string;
    token: string;
    password: string;
  }
  export interface UserProgress {
    upperBody: {
      neck: number;
      shoulders: number;
      chest: number;
    };
    arms: {
      arm: number;
      forearm: number;
      wrist: number;
    };
    middleBody: {
      waist: number;
      hips: number;
    };
    legs: {
      thigh: number;
      calf: number;
      ankle: number;
    };
    foldThickness: {
      abdominal: number;
      thigh: number;
      triceps: number;
      pelvicBone: number;
      fats: number;
    };
    date: string;
    total: number;
    currentWeight: number;
    _id: string;
  }
  
  export interface UserDetails {
    name: string;
    age: number;
    height: number;
    gender: string;
    profilePicture: string;
    email: string;
    weight: number;
    progress: UserProgress[];
    weightUnit:string;
  }

  interface ProfileDetails {
    name: string;
    age: number;
    height: number;
    gender: string;
    weight: number;
    weightUnit: string;
    profilePicture: string;
  }

  interface Settings {
    weightUnit: string;
    themeMode: string;
    soundMode: string;
    tipsMode: string;
  }

  interface UpcomingWorkout {
    workoutName: string;
    date: string;
  }

  interface Measurements {
    currentWeight: number;
    upperBody: { neck: number; shoulders: number; chest: number; };
    arms: { arm: number; forearm: number; wrist: number; };
    middleBody: { waist: number; hips: number; };
    legs: { thigh: number; calf: number; ankle: number; };
    foldThickness: { abdominal: number; thigh: number; triceps: number; pelvicBone: number; fats: number; };
  }
  
  export function register(email: string, password: string) {
    const userData: UserData = { email, password };
    return axiosInstance.post('/users/register', userData);
  }
  
  export function login(email: string, password: string) {
    const userData: UserData = { email, password };
    return axiosInstance.post('/users/login', userData);
  }

  export function googleLogin(credentials: any) {
    return axiosInstance.post('/users/googleLogin', credentials);
  }
  
  export function logout() {
    return axiosInstance.get('/users/logout');
  }

  export function getUserDetails(userId: string) {
    return axiosInstance.get(`/users/profile/${userId}`);
  }

  export function updateUserDetails(userId: string,name: string,age: number,height: number,
    gender:string,weight:number, weightUnit: string, profilePicture:string, image: Blob | string) {
      const userDetails: ProfileDetails = {name, age, height, gender, weight, weightUnit, profilePicture};
      const formData = new FormData();
      formData.append('image', image);
      formData.append('data', JSON.stringify(userDetails));
      
      return axiosInstance.put(`/users/profile/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }});
  }

  export function getUserWeightUnit(userId: string) {
    return axiosInstance.get(`/users/${userId}/weightUnit`)
  }

  export function editUserWeightUnit(userId: string, newWeightUnit: string) {
    return axiosInstance.put(`/users/${userId}/weightUnit?newWeightUnit=${newWeightUnit}`)
  }

  export function getUserThemeMode(userId: string) {
    return axiosInstance.get(`/users/${userId}/themeMode`)
  }

  export function editUserThemeMode(userId: string, newThemeMode: string) {
    return axiosInstance.put(`/users/${userId}/themeMode?newThemeMode=${newThemeMode}`)
  }

  export function getUserSoundMode(userId: string) {
    return axiosInstance.get(`/users/${userId}/soundMode`)
  }

  export function editUserSoundMode(userId: string, newSoundMode: string) {
    return axiosInstance.put(`/users/${userId}/soundMode?newSoundMode=${newSoundMode}`)
  }

  export function getUserTipsMode(userId: string) {
    return axiosInstance.get(`/users/${userId}/tipsMode`)
  }

  export function editUserTipsMode(userId: string, newTipsMode: string) {
    return axiosInstance.put(`/users/${userId}/tipsMode?newTipsMode=${newTipsMode}`)
  }

  export function addUpcomingWorkout(userId: string,workoutName: string, date:string) {
    let upcomingWorkout: UpcomingWorkout = { workoutName, date }
    return axiosInstance.post(`/users/${userId}/addUpcomingWorkout`,upcomingWorkout)
  }

  export function getUpcomingWorkouts(userId: string) {
    return axiosInstance.get(`/users/${userId}/getUpcomingWorkouts`)
  }

  export function getCompletedWorkouts(userId: string) {
    return axiosInstance.get(`/users/${userId}/getCompletedWorkouts`)
  }

  export function deleteUpcomingOrCompletedWorkout(userId: string, workoutId: string) {
    return axiosInstance.delete(`/users/${userId}/workouts/${workoutId}`)
  }

  export function sumTotalSetsForTimePeriod(userId: string, timePeriod: string) {
    return axiosInstance.get(`/users/${userId}/totalSets?timePeriod=${timePeriod}`)
  }

  export function sumTotalSetsByDate(userId: string, timePeriod: string) {
    return axiosInstance.get(`/users/${userId}/totalSetsByDate?timePeriod=${timePeriod}`)
  }

  export function requestResetPassword(email: string) {
    return axiosInstance.post('/users/requestResetPassword', { email });
  }

  export function resetPassword(userId: string, token: string, password: string) {
    const resetData: ResetData = { userId, token, password };
    return axiosInstance.post('/users/resetPassword', resetData);
  }

  export function addProgressRecord(userId: string, measurements:Measurements) {
    return axiosInstance.post(`/users/${userId}/addProgress`,{measurements})
  }

  export function getAllProgressRecords(userId: string) {
    return axiosInstance.get(`/users/${userId}/progress`)
  }

  export function getOneProgressRecord(userId: string, progressId: string) {
    return axiosInstance.get(`/users/${userId}/oneProgress/${progressId}`)
  }

  export function deleteOneProgressRecord(userId: string, progressId: string) {
    return axiosInstance.delete(`/users/${userId}/oneProgress/${progressId}`)
  }

  export function getUserSettings() {
    return axiosInstance.get('/users/userSettings')
  }

  export function updateUserSettings(settings:Settings) {
    const {weightUnit,themeMode,soundMode,tipsMode} = settings;
    return axiosInstance.put(`/users/userSettings?weightUnit=${weightUnit}&themeMode=${themeMode}&soundMode=${soundMode}&tipsMode=${tipsMode}`)
  }

  export function autoLogin(dispatch:any) {
  //  const checkAuthentication = (state:any): boolean => !!isAuthenticated(state)
    let userDataString = sessionStorage.getItem('userData')

    if(!userDataString) {
      dispatch(setAuthentication(null))
      return
    }

    let userData;
    if(userDataString) {
        userData = JSON.parse(userDataString)
    }
    let accessToken = userData?.accessToken;
    dispatch(setAuthentication(accessToken))

  }