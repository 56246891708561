import { FaArrowLeft, FaArrowAltCircleLeft } from "react-icons/fa";
import React from "react";
import { unstable_HistoryRouter, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ArrowLeft = () => {
  const navigate = useNavigate();

  return (
      
      <FontAwesomeIcon icon={faArrowLeft} className="arrow-left" onClick={()=>navigate(-1)}/>
  );
};

export default ArrowLeft