import React, { Suspense, lazy } from "react";
import {useState, useEffect} from "react";
import logo from "./logo.svg";
import { Counter } from "./features/counter/Counter";
import "./App.css";
import './App.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
//import LoginPage from "./pages/LoginPage/LoginPage";
//import ProfilePage from "./pages/Profile/ProfilePage";
//import EditProfile from "./pages/Profile/EditProfile";
//import ProgressPage from "./pages/Progress/Progress";
import ClientsPage from "./pages/ClientsPage";

//import SettingsPage from "./pages/Settings/SettingsPage";
//import WorkoutsListPage from "./pages/WorkoutListPage/WorkoutsListPage";
import WorkoutPage from "./pages/WorkoutPage";
import PrivateWrapper from "./utils/PrivateWrapper";
import LoginWrapper from "./utils/LoginWrapper";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import GoogleAuthPage from "./pages/GoogleAuthPage";
import DietPage from "./pages/DietPage/DietPage";
//import WorkoutInfoPage from "./pages/WortkoutInfoPage/WorkoutInfoPage";
//import HistoryPage from "./pages/History/History";
import CreateWorkout from "./pages/WorkoutListPage/components/CreateWorkout";
//import ProgressRecord from "./pages/ProgressRecord/ProgressRecord";
//import CreateWorkoutTab from "./pages/WorkoutListPage/components/CreateWorkoutTab";
import HeaderComponent from "./components/Common/HeaderComponent";
import FooterComponent from "./components/Common/FooterComponent";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "./app/store";
import { autoLogin } from "./services/userService";
//import Test from "./pages/WorkoutListPage/components/test";
import { Toaster, toast } from 'react-hot-toast';


const LoginPage = lazy(() => import("./pages/LoginPage/LoginPage"));
const ProfilePage = lazy(() => import("./pages/Profile/ProfilePage"));
//const EditProfile = lazy(() => import("./pages/Profile/EditProfile"));
const ProgressPage = lazy(() => import("./pages/Progress/Progress"));
const SettingsPage = lazy(() => import("./pages/Settings/SettingsPage"));
const WorkoutsListPage = lazy(() => import("./pages/WorkoutListPage/WorkoutsListPage"));
const WorkoutInfoPage = lazy(() => import("./pages/WortkoutInfoPage/WorkoutInfoPage"));
const HistoryPage = lazy(() => import("./pages/History/History"));
const ProgressRecord = lazy(() => import("./pages/ProgressRecord/ProgressRecord"));
const CreateWorkoutTab = lazy(() => import("./pages/WorkoutListPage/components/CreateWorkoutTab"));
//const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"))

const checkAuthentication = (state:any): boolean => !!isAuthenticated(state);

function App() {

const dispatch = useDispatch()

useEffect(() => {
  autoLogin(dispatch)
},[])

const authentication = useSelector(checkAuthentication);

let routes = <Routes>
<Route element={<LoginPage /> } path="/" />
<Route element={<LoginPage />} path="login" />
<Route element={<WorkoutsListPage />} path="workouts" />
<Route element={<WorkoutInfoPage />} path="workout/:workoutId" />
<Route element={<Navigate to="/" />} path="*"/>
</Routes>

// route guards
if(authentication) {
  routes = <Routes>
<Route element={<WorkoutsListPage />} path="/" />
<Route element={<WorkoutsListPage />} path="workouts" />
<Route element={<WorkoutInfoPage />} path="workout/:workoutId" />
<Route element={<ProfilePage />} path="profile" />
<Route element={<ProgressPage />} path="progress" />
<Route element={<HistoryPage/>} path="history" />
<Route element={<SettingsPage/>} path="settings" />
<Route element={<ProgressRecord/>} path="progress-record" />
<Route element={<CreateWorkoutTab />} path="create"></Route>
<Route element={<Navigate to="/" />} path="*"/>
  </Routes>
}

  return (
 
    <Router>
      <HeaderComponent />
      <Toaster containerStyle={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} reverseOrder={false} />
      <Suspense fallback={<div>Loading...</div>}>
          {routes}
        </Suspense>
      <FooterComponent />
    </Router>

      ) 
}

export default App;


 /* <Router>
      <HeaderComponent />
      <Routes>
        
         <Route element={<LoginWrapper/>}>
          <Route element={<LoginPage />} path="login" />
          <Route element={<GoogleAuthPage/>} path="login" />
        </Route> 
        
        <Route element={<PrivateWrapper />}>
          <Route element={<LoginPage />} path="/"></Route>
          <Route element={<ProfilePage />} path="profile" />
          <Route element={<ProgressPage />} path="progress" />
  
          <Route element={<HistoryPage/>} path="history" />
          <Route element={<SettingsPage/>} path="settings" />
          <Route element={<WorkoutsListPage />} path="workouts" />
          <Route element={<WorkoutInfoPage />} path="workout/:workoutId" />
          <Route element={<DietPage/>} path="diet/:name" />
          <Route element={<LoginPage />} path="login" />
          <Route element={<ProgressRecord/>} path="progress-record" />
          <Route element={<CreateWorkout/>} path="create"></Route> 
         <Route element={<CreateWorkoutTab />} path="create"></Route>
          <Route element={<EditProfile />} path="update" />
           <Route element={<Test/>} path="test"></Route> 
        </Route>

        <Route element={<PrivacyPolicyPage/>} path="privacy-policy" />
        <Route element={<Navigate to="/" />} path="*"/>
      
      </Routes>
      <FooterComponent />
      </Router> 
    */
