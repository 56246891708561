import React, { useRef } from "react";
import ArrowLeft from "./ArrowLeft";
import { useLocation } from "react-router-dom";


const HeaderComponent = () => {
  // const showArrowLeft = sessionStorage.length > 0 && JSON.parse(sessionStorage.userData)?.userId !== null;
  // console.log(showArrowLeft)
  const {pathname, key} = useLocation();
  const start = useRef(key);

  return (
    <header className="header" data-testid="header-component">
      <div className="header-left-side">
        <img src={require("../../styles/Assets/logo.webp")} alt="logo" />
        {/* {showArrowLeft ? <ArrowLeft /> : null} */}
        {/* {profilePage ?  <button className="profile-page-exit-button"> Exit </button> : <ArrowLeft />} */}
        {(pathname !== "/" && key !== start.current) ? <ArrowLeft /> : null}
      </div>

      <p className="header-text"><span className="header-description-text">FitBySide&nbsp;&nbsp;&nbsp;</span></p>
    </header>
  );
};

export default HeaderComponent;
