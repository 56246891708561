import { configureStore, ThunkAction, Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import Workout from '../interfaces/WorkoutInterfaces';
import { addExerciseToWorkout } from '../services/workoutService';


const LoadingSlice = createSlice(
  {
    name: 'loading',
    initialState: true, 
    reducers: {
      setLoading: (state, action) => {
        return action.payload;
      },
    },
  }

);

export const {setLoading}=LoadingSlice.actions;

const authSlice = createSlice(
  {
    name: 'auth',
    initialState: { accessToken:null },
    reducers: {
      setAuthentication: (state,action) => {
        state.accessToken = action.payload;
      }
    }
  }
);

export const { setAuthentication } = authSlice.actions;
export const isAuthenticated = (state:any) => state.auth.accessToken


const formDataSlice= createSlice({
  name: "formData",
  initialState:{},
  reducers:{
    setFormData: (state, action)=>{
      return action.payload
    }
  }

});

export const {setFormData} =formDataSlice.actions;



const uExerciseSlice=createSlice({
 name: "uExercise",
 initialState:{},
 reducers:{
  setUExercise:(state, action)=>{
    return action.payload;
  }
 }
});

export const {setUExercise}=uExerciseSlice.actions;

interface UserWorkoutsState {
  allWorkouts: Workout[];
  singleWorkout: Workout;
}

const initialState: UserWorkoutsState = {
  allWorkouts: [] as Workout[],
  singleWorkout: {} as Workout, // Initialize with an empty object or another default value
};

const userWorkouts = createSlice({
  name: "userWorkouts",
  initialState,
  reducers: {
    setExercisesInSingleWorkout: (state, action)=>{
      state.singleWorkout.exercises = [...state.singleWorkout.exercises, action.payload]

    },
    setUserWorkouts: (state, action: PayloadAction<Workout[]>) => {
      state.allWorkouts = action.payload;
    },
    setSingleWorkout: (state, action: PayloadAction<Workout>) => {

      state.singleWorkout = action.payload;
    },
  },
});

export const { setUserWorkouts, setSingleWorkout, setExercisesInSingleWorkout } = userWorkouts.actions;

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    loading: LoadingSlice.reducer,
    formData: formDataSlice.reducer,
    uExercise: uExerciseSlice.reducer,
    workouts: userWorkouts.reducer,
    auth: authSlice.reducer
  },
});




export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
