import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowAltCircleUp, faFileLines, faCalendar, faObjectGroup, faClock,} from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { faArrowTrendUp, faClockRotateLeft, faEllipsisV, faGear, faHome, faRightFromBracket, faRightLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated, setAuthentication } from "../../app/store";
// import { Navigation } from './Navigation';

const checkAuthentication = (state:any): boolean => !!isAuthenticated(state)

const FooterComponent = () => {
  const authenticated = useSelector(checkAuthentication)
 // const userDataString = sessionStorage.getItem('userData');
 // const userData = userDataString ? JSON.parse(userDataString) : null;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const logout = () => {
    sessionStorage.removeItem('userData');
    dispatch(setAuthentication(null)) 
    navigate('/home'); 
  }
  // const location = useLocation();
  // const currentPathname = location.pathname;
 
  return (
  
    <footer className="footer" data-testid="footer-component">
      {/* <span>Privacy policy</span>
      <span>Contact us</span>
      <span>Terms of use</span>
      <span>FAQ</span> */}

      <ul className="ul-footer" role="list">
       
        {authenticated ? (
          <>
        <li><NavLink to="/workouts"  className="white-text"><span className="link-text">Workouts</span> <FontAwesomeIcon icon={faFileLines} className="footer-icon"/></NavLink></li>
        <li><NavLink to="/progress" className="white-text"><span className="link-text">Progress</span> <FontAwesomeIcon icon={faArrowTrendUp} className="footer-icon"/></NavLink></li>
        <li><NavLink to="/history" className="white-text"><span className="link-text">History</span> <FontAwesomeIcon icon={faClockRotateLeft} className="footer-icon"/></NavLink></li>
        <li><NavLink to="/profile" className="white-text"><span className="link-text">Profile</span> <FontAwesomeIcon icon={faUser} className="footer-icon"/></NavLink></li>
        <li><NavLink to="/settings" className="white-text"><span className="link-text">Settings</span> <FontAwesomeIcon icon={faGear} className="footer-icon"/></NavLink></li>
        {/* <li onClick={logout}> <span className="link-text">Logout </span><FontAwesomeIcon icon={faRightFromBracket} className="footer-icon"/></li> */}
          </>
        )
        : (
          <>
          <li><NavLink to="/workouts"  className="white-text" ><span className="link-text">Workouts</span> <FontAwesomeIcon icon={faFileLines} className="footer-icon"/></NavLink></li>
          <li><NavLink to="/login" className="white-text"><span className="link-text">Login/Register</span> </NavLink></li>
          </>
        )
      }
        
        
      </ul>

    </footer>
  );
};

export default FooterComponent;
// icon={faClock}