import axios from "axios";
import { setLoading, store } from "../app/store";

const axiosInstance = axios.create({
    baseURL: `https://fit-api-ehu5.onrender.com`
})

axiosInstance.interceptors.request.use((config) => {
    let userDataString = sessionStorage.getItem('userData')
    let userData;
    if(userDataString) {
        userData = JSON.parse(userDataString)
    }
    let accessToken = userData?.accessToken;
   // console.log(accessToken)
    if(accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }

   // store.dispatch(setLoading(true))
    return config
});

/*axiosInstance.interceptors.response.use((response) => {
    store.dispatch(setLoading(false))
    return response
},
    (error) => {
        store.dispatch(setLoading(false))
        return Promise.reject(error)
    }
); */

export default axiosInstance